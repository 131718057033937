/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;400;600;700&family=Nunito+Sans:wght@200;300;400&display=swap');

$font-stack: "Poppins", sans-serif;
$font-stack2: 'Nunito Sans', sans-serif;

$dark: #000;
$light: #fff;
$grey: #525252;
$main-purple: #37057a;
$main-pink: #d96bf5;

$small: 576px;
$medium: 768px;
$large: 992px;
$xlarge: 1024px;

/* Global */
body {
  font: 100% $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
::-moz-selection { /* Code for Firefox */
  color: $main-pink;
  background: $main-purple;
}

::selection {
  color: $main-pink;
  background: $main-purple;
}
.container{
  max-width: $xlarge;
  margin: 0 auto;
}
h1{
  text-align: center;
}
h1, h2, h3, h4{
  color:$dark;
}
p{
  line-height: 1.5;
}
button{
  cursor: pointer;
  background-color: $dark;
  color: #fff;
  text-transform: uppercase;
  padding:20px 40px;
  border: none;
  font-size: 1.2em;
  font-weight: 600;
  transition: all 0.3s ease-out;
  border: none;
  background: linear-gradient(to right, $main-purple 50%, $dark 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  box-shadow: 0px 0px 10px -1px rgb(0 0 0 / 30%);
  &:hover{
    background-position: -100%;
    color:$light;
  }
}
.center-align{
  display: flex;
  justify-content: center;
  margin: 40px 0;
}
.page {
  padding: 40px 20px;
}
.sub-head{
  text-align: center;
  font-size: 20px;
  padding-bottom: 10px;
  line-height: 1.5;
}
.tags ul {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  li{
    padding:10px 20px;
    background: $grey;
    color:$light;
    margin-right: 10px;
    font-weight: 600;
    border-radius: 20px;
    a{
      color:$light;
      text-decoration: none;
    }
  }
}
/* END Global */



/* NavBar */
.navbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color:$main-purple;
  height: 100px;
  padding: 0 20px;
  .mainNav a {
      text-decoration: none;
      color: $light;
      padding: 0 10px;
      font-weight: 100;
  }
  .logo{
    img{
      width:200px;
      height:auto;
    }
  }
}

.mobileNav{
  display: none;
  .mobileNav-container{
    -webkit-transition: -webkit-transform .3s ease-in-out;
    -webkit-transition-delay: 0s;
    -moz-transition: -moz-transform .3s ease-in-out 0s;
    -o-transition: -o-transform .3s ease-in-out 0s;
    transition: transform .3s ease-in-out 0s;
    -webkit-transform: translate3d(14.25em, 0, 0);
    -moz-transform: translate3d(14.25em, 0, 0);
    -ms-transform: translate3d(14.25em, 0, 0);
    -o-transform: translate3d(14.25em, 0, 0);
    transform: translate3d(14.25em, 0, 0);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-box-shadow: -1px 0 1px rgb(0 0 0 / 20%);
    -moz-box-shadow: -1px 0 1px rgba(0,0,0,0.2);
    box-shadow: -1px 0 1px rgb(0 0 0 / 20%);
    width: 14em;
    display: block;
    height: 100%;
    position: fixed;
    top: 100px;
    bottom: 0;
    right: -14.25em;
    left: auto;
    z-index: 200;
    background-color: $main-purple;
    display: flex;
    flex-direction: column;
    a{
      color: $light;
      text-decoration: none;
      padding: 20px;
    }
  }
  button{
    background: transparent;
    border: none;
    box-shadow: none;
    img{
      width:30px;
      height:30px;
    }
    img.close{
      display: none;
    }
  }
  &.is-open{
    img.close{
      display: block;
    }
    img.open{
      display: none;
    }
    .mobileNav-container{
      right: 0;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .overlay{display: block;}
  }
}
.overlay{
  position: fixed;
  z-index: 1;
  top: 100px;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
}
/* END NavBar */

/* Home Page */
.home{
  .w-80{
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
  #top-products{
    display: flex;
    .product-card{
      flex: 1 30%;
    }
  }
  h2{
    padding: 0;
    margin: 60px 0 20px 0;
    font-size: 2.6em ;
    font-weight: 600;
    text-align: center;
  }
}
.home-banner{
  background-color: $light;
  min-height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  font-weight: 600;
  .home-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color:$dark;
    h1{
      padding:0;
      margin: 0;
      font-size: 5em;
      text-transform: uppercase;
    }
    .home-sub-head{
      font-size: 2em;
      font-weight: 100;
      font: 2em $font-stack2;
    }
  }
  .header-image{
    width: 250px;
    height:250px;
    border-radius: 250px;
    background-color: $dark;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .brackets{
      font-size: 6em;
      position: relative;
      top: -5px;
      color:$light;
    }
    .bubble{
      position: absolute;
      bottom:15px;
      left:-90px;
      background-color:#fff;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:15px 20px;
      line-height: .5;
      color:$main-pink;
      box-shadow: -7px 7px 0px 0px $main-pink;
    }
  }
}
.split50{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding:40px 20px;
  div{
    flex: 1 45%;
    color: $main-purple;
    img{
      max-width: 100%;
    }
  }
}
#intro-section{
  margin: 40px auto;
  p{
    font-size: 20px;
    line-height: 1.5;
  }
} 
/* END Home Page */

/* Product Page */
.blog{
  h1{
    font-size: 2.6em ;
    font-weight: 600;
    text-align: center;
  }
}
.product-grid{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
/* END Product Page */

/* Product List Detail */
.product-card {
  flex: 1 45%;
  margin:20px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 20%);
  a{
    text-decoration: none;
  }
  .product-details{
    background-color: $light;
    padding: 40px;
    color: $dark;
  }
  .product-title {
    font-size: 20px;
    padding-bottom: 20px;
  }
  .product-description{
    padding-bottom:20px;
    color: $grey;
    line-height: 1.5;
  }
  .price {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;
    min-height: 60px;
    font-weight: 600;
    text-transform: uppercase;
    color:$light;
    transition: all 0.3s ease-out;
    background: linear-gradient(to right, $dark 50%, $main-pink 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    box-shadow: 0px 0px 10px -1px rgb(0 0 0 / 30%);
    &:hover{
      background-position: -100%;
    }
  }
  .tags {
    font-size: 12px;
  }
  .thumbnail{
    background-color: $main-purple;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 170px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
/* END Product List Detail */

/* Product Detail Page */
.single{
  padding:50px 0;
  .single-image{
    text-align: center;
    img{
      max-width: 100%;
      width: 100%;
      max-height: 390px;
      position: relative;
      object-fit: cover;
      vertical-align: middle;
    }
  }
  .single-content{
    h1{
      text-align: left;
    }
  }
  .single-body {
    img{
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    p em{
      font-weight: 600;
      font-style: normal;
      margin-bottom: -20px !important;
      display: block;
      border: 1px solid black;
      border-bottom:none !important;
      width: max-content;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }
}
pre {
  background-color: #282a37;
  padding: 40px;
  color:#fff;
  line-height: 1.5;
  overflow: hidden;
  overflow-wrap: break-word;
  code{
    white-space: pre-wrap;
  }
}
/* End Product Detail Page */

/* Footer */
.footer{
  background-color: $main-purple;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  a, p{
    text-decoration: none;
    color:#fff;
  }
  .copy{
    font-size: 1em;
    display: flex;
  }
  img{
    width:250px;
    height:auto;
  }
}
/* END Footer */

/* Mobile */
@media screen and (max-width: $xlarge) {
  .mainNav{display: none;}
  .mobileNav{display: block;}
}
@media screen and (max-width: $large) {
  .container{max-width: $large;padding-left: 20px;padding-right: 20px;}
  .home #top-products {flex-wrap: wrap;}
  .home-banner{flex-wrap: wrap;justify-content: center;}
  .home-banner .header-image, .home-banner .home-header{margin:40px 0;}
  .split50 {flex-direction: column;}
}
@media screen and (max-width: $medium) {
   h1{font-size: 3em !important;}
  h2{font-size: 2em !important;}
  .container{max-width: $medium;}
  .mobileNav button{padding: 0;}
  a button{font-size: 1.2em;}
  .home-banner, .home-banner h1{text-align: left;}
  .home-banner .header-image {width: 210px;height: 210px;}
  .home-banner .header-image .brackets {font-size: 4em;}
  .home-banner .header-image .bubble{left: -40px;}
  .split50 div{padding:20px;}
  .product-card{flex:0 0 100% !important; margin: 20px 0px;}
}
@media screen and (max-width: $small) {
  h1 {font-size: 2em !important;}
  .container{max-width: $small;}
  .navbar .logo img  {width: 150px;}
  .product-card .thumbnail{min-height: 140px;}
}
/* END Mobile */
